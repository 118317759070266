// Content is the a structural component that defines a grid for everything that appears between the header and footer
.content {
  max-width: $lg;
  margin: 0px auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;

  &.conten-search-mainbody{
    max-width: 100%;
    background-color: $color-light-blue;
  }

  &.content-mainbody-containner{
    max-width: 100%;
    background-color: $color-light-blue;
  @media ($lg-up) {
    padding: 80px;
    border-top: 6px solid #FFFFFF;
  }

  @media screen and (width: 1024px) { 
    padding: 30px; 
  }
  }
  &.content-staffing-mainbody{
    margin-bottom: 80px;
    @media ($lg-up) {
      max-width: 1444px;
      width: 1280px;
    }
  }
  
  .content__body__container{
    
    border: 1px solid #dadada;
    margin-top: 40px;

    @media ($sm-down) {
      margin-top: 20px;
    }

    @media ($lg-up) {
      padding: 40px 60px;
    }
  }

  // Modifier --fullwidth: exclude sidebar and make the primary area fullwidth
  &.content--fullwidth {
    .content__primary {
      @media($sm-up) {
        width: 100%;
      }
      @media($lg-up) {
        width: 100%;
      }
    }
  }

  // Modifier: --spacebelow. Add Space Below the content area
  &.content--spacebelow {
    background-color: #ffffff;
    .content-search-header--block {
      height: auto;
    }
  }

  // Modifier: not --fullwidth. Defined in this way so they are not the 'default'
  // and don't have to be explicitly canceled out for --fullwidth
  &:not(.content--fullwidth) {
    .content__primary {
      
      width: 100%;  
      &.content__primary_search_screen{
        @media ($sm-up) {
          width: 780px;
        }
      }
      &.content-primary-containner{
        max-width: 780px;
        @media ($lg-down) and ($sm-up) {
          max-width: 65%;
        }
      }
    }
  }

  // Modifier --collapsible is used to annotate cards who assume a compact view on
  // small screens, losing their border and taking up more space
  &.content--collapsible {
 
    @media($sm-down) {
      .content__primary {
        padding: 0;
      }
      .card.card--raised {
        box-shadow: none;
        border: 0;
      }
    }
  }

  // Modifier --loading: shows when loading in progress
  &.content__primary--loading {
    &:after {
    }
  }

  // No ratings
  .profileintro__norating {
    align-items: center;
    display: flex;
    flex-direction: row;
    background: #f8f8f8;
    padding: 7px 9px;
    p {
      font-size: 13px;
      padding: 10px;
      width: 220px;
    }
  }

  .content-search-header--block{

    background-color: white;
    display: flex; 
    flex-direction: row;
    justify-content: center;

    @media ($sm-up) {
      width: 100%;
      height: 205px;
      justify-content: center;
    }

    @media ($sm-down) {
      padding: 20px;;
    }

    .content-search-header-block-container{

      display: flex;
      align-items: center;

      @media ($sm-up) {
        width: 1279px;
        padding: 40px;
      }

      @media ($lg-up) {
        gap: 50px;
        padding: 0;
      }
    }
  }

  .conten-search-body-block{

    @media ($sm-up) {
      width: 100%; 
      gap: 50px;
      display: flex; 
      flex-direction: row;
      justify-content: center;
    }

    .conten-search-body-block-container{

      justify-content: center;
      
      @media ($sm-up) {
        display: flex;
        width: 100%;
        padding: 40px;
        gap: 30px;
      }

      @media ($lg-up) {
        gap: 50px;
        padding: 80px;
      }
    }

    @media ($sm-down) {
      margin: 20px;
    }
  }

  /***** END TOP-LEVEL MODIFIERS, begin nested styles *****/
  // Content Header
  .content__header {

    width: 100%;

    // Modifier: --simple header
    &.content__header--simple {

      padding: 0 20px;
      margin-bottom: 40px;
      margin-top: 80px;

      @media ($lg-up) {
        padding: 0;
      }

      h1, h2 {

        font-family: "Matter", sans-serif;
        font-weight: 400;
        font-size: 36px;
        line-height: 140%;
        letter-spacing: -2%;
        vertical-align: middle;

        @media ($sm-down) {
          font-size: 24px;
        }
      }
    }

    // Modifier: --flatbotton header
    &.content__header--flatbottom {
      h1, h2 {
        margin-bottom: 0;
        @media($sm-up) {
          margin-bottom: 0;
        }
      }
    }

    // Logo
    .content__header__logo {
      width: 10%;
      text-align: center;
      .center-helper {
        display: inline-block;
        vertical-align: middle;
      }
      img {
        width: 100%;
        vertical-align: middle;
      }
    }

    // Modifier: --logospacing header
    &.content__header--logospacing {
      display: flex;
    }

    // Intro
    .content__header__intro {
      margin: auto 20px;
      p {
        font-weight: 300;
        font-size: 18px;
        margin-top: -5px;

        a {
          color: #094c3d;
          font-weight: 400;
        }
      }

      h1 {
        font-size: 36px;
      }
    }

    // Modifier: --haslogo header
    &.content__header--haslogo {
      padding-right: 100px;
      @media($sm-up) {
        padding-right: 210px;
      }
    }

    // Hidden
    &.content__header--hidden {
      display: none;
    }

    h1 {
      font-family: 'Matter', sans-serif;
      font-weight: 400;
      font-size: 36px;
      line-height: 50.4px;
      letter-spacing: -2%;
    }

    h2 {

      font-family: 'Matter', sans-serif;
      font-weight: 400;
      font-size: 36px;
      line-height: 50.4px;
      letter-spacing: -2%;
    }

    p {
      font-size: 24px;
      margin: 0;
      color: #000000;
      @media($md-up) {
        font-family: 'Matter', sans-serif;
        font-weight: 400;
    
        line-height: 33.6px;
        letter-spacing: -2%;

      }

      button {
        border: 0;
        background: transparent;
        padding: 0;
        font-size: inherit;
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: $color-text-green-dark;;
        }
      }
    }
  }

  /***** END TOP-LEVEL MODIFIERS, begin nested styles *****/
  // Content Header
  .content__main__header {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;

    @media($lg-up) {

      padding: 80px 0;
      align-items: center;
      gap: 29px;
      border-top: 1px solid #D5D5D5;
    }

    // Modifier: --simple header
    &.content__main__header--simple {

      h1, h2 {
        color: $color-text;
        font-size: 20px;
        @media($sm-up) {
          font-size: 28px;
        }
      }

      @media ($lg-down) {
        padding: 10px;
      }
    }

    // Modifier: --flatbotton header
    &.content__main__header--flatbottom {
      h1, h2 {
        margin-bottom: 0;
        @media($sm-up) {
          margin-bottom: 0;
        }
      }
    }

    // Logo
    .content__main__header__logo {
      width: 10%;
      text-align: center;
      .center-helper {
        display: inline-block;
        vertical-align: middle;
      }
      img {
        width: 100%;
        vertical-align: middle;
      }
    }

    // Modifier: --logospacing header
    &.content__main__header--logospacing {
      display: flex;
    }

    // Intro
    .content__main__header__intro {
      margin: auto 20px;
      p {
        font-weight: 300;
        font-size: 18px;
        margin-top: -5px;
      }

      h1 {
        font-size: 36px;
      }
    }

    // Hidden
    &.content__main__header--hidden {
      display: none;
    }

    h1 {
      font-weight: normal;
      font-size: 24px;
      margin: 0 0 10px 0;
      @media($sm-up) {
        font-size: 36px;
      }
      @media($md-up) {
        font-size: 44px;
      }
    }

    h2 {
      font-weight: normal;
      font-size: 20px;
      margin: 0 0 10px 0;
      @media($sm-up) {
        font-size: 32px;
      }
      @media($md-up) {
        font-size: 40px;
      }
    }

    p {
      font-size: 12px;
      margin: 0;
      @media($md-up) {
        font-size: 20px;
      }

      button {
        border: 0;
        background: transparent;
        padding: 0;
        font-size: inherit;
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: $color-blue-corporate;
        }
      }
    }

    .content__main__header_winnerText{

      @media ($sm-up){

        width: 680px;
        height: 166px;
        font-size: 20px;

        h2{
          position: relative;
          top: 25px;
          font-weight: 400;
          font-size: 48px;
          font-family: "Matter", sans-serif;
          line-height: 60px;
          letter-spacing: -0.28px; 
        }
      }

      p {  
        font-weight: 400;
        font-size: 14px;
        font-family: "Matter", sans-serif;
        line-height: 21px;
        letter-spacing: 2%;
        text-transform: uppercase;
      }
  
      @media ($sm-down) {
        p{
          font-size: 8px;
        }
      }

      .header_winnerText_topcontainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;
      }

      .green_dot{
        width: 12px;
        height: 12px;
        border-radius: 132px;
        background: #0F7D64;
      }
    }
  }

  // Primary content area
  .content__primary {
    width: 100%;
    position: relative;
    opacity: 1;
    transition: .25s $springIn opacity;
    &.content__primary--isloading {
      opacity: .5;
      pointer-events: none;
    }
  }

  // Secondary content area
  .content__secondary {
    width: 100%;
    @media($sm-up) {
      width: 449px;
    }
    @media($lg-up) {
      width: 449px;
    }
  }
  .card-content-block-container {
    display: flex;
    flex-wrap: nowrap; 
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 50px;
    padding-top: 20px;
    margin: 7px auto;
    margin-bottom: 30px;

    @media ($xl-up) {
      // padding: 30px; 
    }
    @media ($lg-up) {
      gap: 30px; 
      padding-top: 30px; 
    }
    @media ($md-down) { 
      width: 90%;
      gap: 30px; 
      padding-top: 20px;
      margin: 0 auto; 
    }
    @media (max-width: 767px) { 
      width: 100%;
      flex-direction: column; 
    }
    @media ($lg-up) {
      width: 1279px;
    }
  }

      
// Primary content area
&.content-primary-containner {
  flex: 1; // Takes available space but respects max-width
  max-width: calc(100% - 320px);
  padding: 15px;

  @media (min-width: 1024px) { // Large screens (desktop)
    max-width: calc(100% - 450px); // Adjust width to leave space for secondary
  }

  @media (max-width: 1023px) and (min-width: 768px) { // Medium screens (tablets)
    max-width: 65%;
    margin: 20px auto;
  }

  @media (max-width: 767px) { // Small screens (mobile)
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
}

// Secondary content area
&.content-secondary-containner {
  max-width: 449px; // Fix width so it does not get pushed down
  flex-shrink: 0; // Prevents shrinking
  height: auto;
  margin-top: 25px;
  padding: 15px;

  @media (min-width: 768px) { // Medium and large screens
    width: 449px;
    padding: 0px;
  }

  @media (max-width: 1023px) { // Medium screens
    margin-top: 0px; // Prevents unwanted space
  }

  @media (max-width: 767px) { // Small screens (mobile)
    max-width: 100%; // Allow full width
    margin-top: 15px; // Push below primary
  }
}

  .content__bestmatch-button {
    @media($xs-down) {
      display: none;
    }
  }

  // Best match link
  .content__bestmatch-link {
    display: none;

    &:active {
      color: $color-text-green-dark;
    }
    &:hover {
      color: $color-text-green-dark;
    }
    &:visited {
      color: $color-text;

      &:hover {
        color: $color-text-green-dark;
      }
    }

    @media($xs-down) {
      display: inline;
    }
  }


}