$opensans: 'Open Sans';
/* Sans-serif, with system font stack approach https://css-tricks.com/snippets/css/system-font-stack/ */
$sanserif: $opensans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

// Breakpoints
$min: 200px;
$min-up: "min-width: 200px";
$min-down: "max-width: 199px";
$micro: 320px;
$micro-up: "min-width: 320px";
$micro-down: "max-width: 319px";
$xs: 480px;
$xs-up: "min-width: 480px";
$xs-down: "max-width: 479px";
$sm: 768px;
$sm-up: "min-width: 768px";
$sm-down: "max-width: 767px";
$md: 992px;
$md-up: "min-width: 992px";
$md-down: "max-width: 991px";
$lg: 1200px;
$lg-up: "min-width:1200px";
$lg-down: "max-width:1199px";
$lg-adjusted: "min-width:1220px";
$xl: 1500px;
$xl-up: "min-width:1500px";
$xl-down: "max-width:1499px";
$max: 2000px;
$max-up: "min-width:2000px";
$max-down: "max-width:1999px";

// Colors
$color-blue-corporate: #0096A3;
$color-blue-dark: #00717A;
$color-green-corporate: #9FCC3B;
$color-green-dark: #67943F;
$color-orange: #F79020;
$color-text: #343434;
$color-placeholder: #414141;
$color-dullgrey: #808080;
$color-error: #ae5226;
$color-linework: #d7d7d7;
$color-light-grey: #9c9c9c;
$color-black: #000000;
$color-white: #ffffff;
$color-light-blue: #E7F2F0;
$color-text-green-dark: #094C3D;


// Animation
$springIn: cubic-bezier(0.52, 0.01, 0.16, 1);
