.targetedlist {
  margin: 30px 0;
  width: 100%;
  display: none;
  gap: 22px;
  margin-top: 0;
  .show-toggle-btn{
    width: 115px;
    height: 56px;
    border-radius: 4px;
    background-color: #171717;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    display: block;
    margin: 20px auto; 
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
  }  

  .show-toggle-btn:hover {
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2); 
  }
  
  // Modifiers: --active for toggling, --persistent do not toggle
  &.targetedlist--active, &.targetedlist--persistent {
    display: block;
    column-count: 1;
    @media ($sm-up) { column-count: 2; }
    @media ($sm-down) { column-count: 1; }
    &.targetedlist--threeColumn {
      @media ($sm-up) { column-count: 3; }
    }
    &.targetedlist--twoColumn {
      @media ($sm-up) { column-count: 2; }
    }
    @media ($lg-down) {
      padding: 20px;
    }
  }

  .targetedlist__single {
    display: inline-block;
    padding: 0 15px;
    width: 100%;
    border: 1px solid #DADADA;
    padding: 11px;
    margin-top: 20px;
    height: 69px;

    .targetedlist-image-logo{
      width:36px;
      height:36px;
      vertical-align: middle;
      position: absolute;
      margin-top: 5px;
    }
    
    a {

      font-family: "Matter", sans-serif;
      font-weight: 400;
      font-size: 1.1rem;
      letter-spacing: 0%;
      vertical-align: middle;
      color: #000000;
      text-decoration: none;
      justify-content: space-between;
      display: flex;
      justify-content: space-between;
      align-items:center;
      position: relative;
      &:hover, &:focus { text-decoration: underline; }

      @media ($lg-down) {
        
        font-size: 20px;
      }
      
       /* Arrow icon */
       .targetedlist-arrow-icon {
        width: 44px;
        height: 44px;
        margin-left: auto;
    }
    }
    .hasaward{
      margin-left: 50px;
    }
  }

  .targetedlist__single_list{

  }

  .targetedlist__multi-line-item {
    color: #343434;
    text-decoration: none;
    padding: 15px 10px;
    padding-left: 40px;
    background-image: url('/static/src/img/ui-triangleright-blue.svg');
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 15px center;
    justify-content: space-between;
    position: relative;
    break-inside: avoid-column;
  }

  .targetedlist__item-link {
    color: #343434;
    &:hover {
      color: $color-blue-corporate;
    }
  }

}

