// Mixins, Placeholders, and Variables
@import 'partials/variables';
@import "partials/content";
@import "partials/targetedlist";

body {
  // background: $color-placeholder;
  background: rgb(255, 255, 255);
}

.header {
  padding-bottom: 10px;
  border-bottom: solid 10px $color-green-corporate;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  @media($md-up) {
    padding: 0px 15px;
  }
}

.header__actions {
  max-width: $lg;
  text-align: center;
  margin: 40px auto 30px auto;
  padding: 0px 10px;
  @media($xs-up) {
    padding: 0px 15px;
  }
  @media($sm-up) {
    margin: 110px auto 120px auto;
    padding: 0;
  }

  h1 {
    color: $color-dullgrey;
    font-weight: normal;
    margin: 0 0 20px 0;
    font-size: 24px;
    @media($sm-up) {
      font-size: 32px;
    }
    @media($md-up) {
      font-size: 40px;
    }
  }

  p {
    color: $color-dullgrey;
    font-weight: lighter;
    margin: 0;
    font-size: 14px;
    @media($md-up) {
      font-size: 20px;
    }
  }

}

.logo-screen {
  display: none;
}

.logo-print {
  display: inline;
}

.header__actions {
  position: relative;
  z-index: 2;
  max-width: $lg;
  color: white;
  text-align: center;
  margin: 40px auto 30px auto;
  padding: 0px 10px;
  @media($xs-up) {
    padding: 0px 15px;
  }
  @media($sm-up) {
    margin: 110px auto 120px auto;
    padding: 0;
  }

  h1 {
    font-weight: normal;
    margin: 0 0 20px 0;
    font-size: 24px;
    @media($sm-up) {
      font-size: 32px;
    }
    @media($md-up) {
      font-size: 40px;
    }
  }

  p {
    font-weight: lighter;
    margin: 0;
    font-size: 14px;
    @media($md-up) {
      font-size: 20px;
    }
  }

}

.card {
  .profilecontact {
    div.profilecontact__linkset__single {
      display: none;
    }
  }
}

.card__header--withline {
  margin-bottom: -20px;
  position: relative;
}

.profiletestimonials__collection {
  margin-top: -45px;
}

.targetedawardlogos__single {
  height: 150px;
  width: 150px;
}

.header {

  .header__primary {
    .logo-print {
      display: flex;
      justify-content: center;
    }
  }
}

.footer {
  border-top: solid 10px $color-blue-corporate;
  -webkit-font-smoothing: antialiased;
  @media($md-up) {
    border-top: solid 20px $color-blue-corporate;
  }

  hr {
    background: #FFF;
    border: 0;
    height: 2px
  }

  // Legal Copy
  .footer__legal {
    max-width: $lg;
    margin: 0px auto;
    font-size: 12px;
    padding: 10px 15px 15px 15px;

    @media($md-up) {
      padding-top: 40px;
      font-size: 14px;
    }

    p {
      margin: 5px 0;
      &:first-of-type {
        margin-top: 25px;
        @media($md-up) {
          margin-top: 50px;
        }
      }
    }
  }
}

// Suppress debug
div[id^="flDebug"] {
  display: none;
}

div[style^="background-color:yellow;text-align:center;"] {
  display: none;
}

// Suppress non-print
button,
.buttongroup,
.footer__legal img,
.footer__links,
.header__primary__link,
.header__primary__logo,
.homeindustries__promos,
.loadanimation,
.loadanimation--isloading,
.pagination__stepper,
.profilecontact.profilecontact__linkset--full,
.searchform,
form,
select {
  display: none;
}
